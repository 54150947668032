import { useContext, useEffect, useReducer, useState } from "react";
import {
  ProjectStagesReducer,
  initializeProjectStages,
} from "../../reducers/project-stages.reducer";
import {
  CreateProjectDto,
  CreateProjectFormData,
} from "./create-project.schema";
import { useProjectAPI } from "../../services/projects.service";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCompanyAPI } from "../../services/companies.service";
import { PageTitleDispatchContext } from "../../contexts/PageTitleProvider";
import { handleAPIError } from "../../services/utils";
import { ErrorHandler } from "../../components/layout/ErrorDisplay";
import { formatDate, formatPhoneNumberIntl } from "../../constants/utils";
// import { useGeolocation } from "../../hooks/useGeolocation";
import { ProjectForm } from "./ProjectForm";
import { Notify } from "../../components/layout/Notify";
import { validateProjectStages } from "./validateProjectStages";
import { calculateRangeAverage } from "./CreateProject";

export function UpdateProject() {
  const pageTitleDispatch = useContext(PageTitleDispatchContext);
  const { useCreateCompanyByName } = useCompanyAPI();
  const { projectID } = useParams();

  useEffect(() => {
    if (pageTitleDispatch) {
      pageTitleDispatch({
        type: "update",
        payload: "Update Project",
      });
    }
  }, [pageTitleDispatch]);

  const { getProjectByID, useUpdateProject } = useProjectAPI();

  // const {
  //   coords,
  //   geolocationError,
  //   promptUserLocation,
  //   geolocationStatusInfo,
  // } = useGeolocation();

  // useEffect(() => {
  //   setResponseError(geolocationError);
  // }, [geolocationError]);

  const [initFormData, setInitFormData] = useState<
    Partial<CreateProjectFormData>
  >({
    address: "",
    architect: undefined,
    description: "",
    estimatedValue: "",
    leadContractor: undefined,
    name: "",
    siteManager: "",
    siteManagerContact: "",
    isCompleted: false,
    type: "",
  });
  const updateProjectViaAPI = useUpdateProject();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const getProjectDetailsQuery = useQuery(["projects", projectID], () => {
    return getProjectByID(projectID);
  });

  const [responseError, setResponseError] = useState<unknown>();
  const [projectStages, dispatch] = useReducer(
    ProjectStagesReducer,
    initializeProjectStages()
  );

  useEffect(() => {
    // Only reset data when projectID exists. Edge case: Data is changed by another KUM.
    if (projectID && getProjectDetailsQuery.data) {
      const {
        projectStages: projectStagesData,
        longitude,
        latitude,
        id: projectID,
        startDate, // Type Datex
        endDate,
        ...updateProjectFormData
      } = getProjectDetailsQuery.data;
      if (projectStagesData)
        dispatch({
          type: "reset",
          payload: projectStagesData,
        });
      setInitFormData({
        ...updateProjectFormData,
        startDate: startDate ? formatDate(startDate) : "",
        endDate: endDate ? formatDate(endDate) : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectDetailsQuery.data]);

  const onSubmit = (data: CreateProjectFormData) => {
    data.siteManagerContact = formatPhoneNumberIntl(data.siteManagerContact);

    const invalidStages = validateProjectStages(projectStages);
    if (invalidStages.length > 0) {
      setResponseError(
        `The following on going stage(s) MUST contain AT LEAST one company: ${invalidStages.join(
          ", "
        )}`
      );
      return;
    }
    if (projectID) {
      const projectDtoData: CreateProjectDto = {
        ...data,
        projectStages,
        avgEstimatedValue: calculateRangeAverage(data.estimatedValue),
      };
      updateProjectViaAPI.mutate(
        {
          createProjectDto: projectDtoData,
          projectID: projectID,
        },
        {
          onSuccess: () => {
            setShowSuccessDialog(true);
          },
          onError: (error) => {
            console.error(error);
            setResponseError(error);
          },
        }
      );
    }
  };
  const createUpdateCompanyAPI = useCreateCompanyByName();

  if (getProjectDetailsQuery.status === "loading") {
    return <p>Loading project details...</p>;
  }

  if (getProjectDetailsQuery.status === "error") {
    return (
      <ErrorHandler error={handleAPIError(getProjectDetailsQuery.error)} />
    );
  }

  function handlePartialCompanyCreation(newOption: string) {
    return createUpdateCompanyAPI.mutateAsync(
      { name: newOption },
      {
        onError(error) {
          setResponseError(error);
        },
      }
    );
  }

  return (
    <>
      <ProjectForm
        onSubmit={onSubmit}
        responseError={responseError}
        initFormValues={initFormData}
        handleOptionCreation={handlePartialCompanyCreation}
        isEditMode={true}
        projectStages={projectStages}
        updateProjectStage={dispatch}
      />
      <Notify
        isVisible={showSuccessDialog}
        message="Project updated successfully."
        url="/projects"
      />
    </>
  );
}
